<template>
  <div>
    <div class="card card-custom">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Campanhas</h5>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            href="#"
            class="btn btn-sm btn-success font-weight-bold"
            @click.prevent="abrirMdlCadastrarCampanha"
          >
            <i class="fa fa-plus"></i> Cadastrar Campanha
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row" v-if="totalRegistros > 0">
          <div class="col-md-6">
            Total de mensagens enviadas {{ quantidadeMensagens }}.
          </div>
          <div class="col-md-6 text-right">
            Exibindo de {{ (paginaAtual - 1) * registrosPorPagina + 1 }} a
            {{ Math.min(paginaAtual * registrosPorPagina, totalRegistros) }} de
            um total de {{ totalRegistros }} registros.
          </div>
        </div>

        <b-table
          hover
          small
          show-empty
          :fields="cabecalho"
          :items="linhas"
          :current-page="paginaAtual"
          :per-page="registrosPorPagina"
        >
          <template #empty>
            <p class="text-center mb-0">Nenhum registro encontrado.</p>
          </template>

          <template #cell(actions)="row">
            <b-button
              variant="light-primary"
              size="sm"
              class="btn-icon mr-2"
              v-b-tooltip.noninteractive
              title="Novo envio"
              @click="abrirMdlEnviarCampanha(row.item)"
            >
              <i class="fas fa-file-upload"></i>
            </b-button>

            <b-button
              variant="light-primary"
              size="sm"
              class="btn-icon mr-2"
              v-b-tooltip.noninteractive
              title="Detalhes"
              @click="abrirMdlDetalheCampanha(row.item)"
            >
              <i class="fas fa-search"></i>
            </b-button>

            <b-button
              variant="light-primary"
              size="sm"
              class="btn-icon mr-2"
              v-b-tooltip.noninteractive
              title="Editar"
              @click="editarCampanha(row.item, row.index)"
            >
              <i class="fas fa-edit"></i>
            </b-button>

            <b-button
              variant="light-danger"
              size="sm"
              class="btn-icon"
              v-b-tooltip.noninteractive
              title="Excluir"
              @click="excluirCampanha(row.item, row.index)"
            >
              <i class="fas fa-trash"></i>
            </b-button>
          </template>
        </b-table>

        <div class="row" v-if="totalRegistros > 0">
          <div class="col-md-4">
            <b-form-group>
              <b-form-select
                :options="sltRegistrosPorPagina"
                v-model="registrosPorPagina"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-8">
            <b-pagination
              :total-rows="totalRegistros"
              v-model="paginaAtual"
              :per-page="registrosPorPagina"
              align="fill"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="mdlCadastrarCampanha"
      ref="mdlCadastrarCampanha"
      title="Cadastrar Campanha"
      @hidden="limparFrmCadastrarCampanha"
      @shown="validacaoMdlCadastrarCampanha"
      @ok="salvarCampanha"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      scrollable
      size="lg"
    >
      <b-form id="frmCadastrarCampanha" ref="frmCadastrarCampanha">
        <b-form-group>
          <label>Título:</label>
          <b-form-input
            name="titulo"
            v-model="frmCadastrarCampanha.titulo"
            placeholder="Exemplo: Campanha Anual"
          ></b-form-input>
        </b-form-group>

        <p>
          <b>Variáveis:</b>
          <br />
          <b>Nome:</b> utilize a tag <b>{nome}</b> para inserir o nome no texto
          de sua mensagem, a tag será substituída pela coluna de nome definida
          posteriormente no arquivo de lote.<br />
          <b>Outras variáveis:</b> {variavel1}, {variavel2}, {variavel3},
          {variavel4}
        </p>

        <b-form-group>
          <label>Texto SMS:</label>
          <b-form-textarea
            name="textoSMS"
            v-model="frmCadastrarCampanha.textoSMS"
            placeholder="Exemplo: Prezado(a) {nome}, desejamos boas-vindas..."
            rows="3"
          ></b-form-textarea>
        </b-form-group>

        <template v-if="this.contaEnviaWhatsapp">
          <b-form-group>
            <label>Texto Whatsapp:</label>
            <b-form-textarea
              name="textoWhatsapp"
              v-model="frmCadastrarCampanha.textoWhatsapp"
              placeholder="Exemplo: Prezado(a) {nome}, desejamos boas-vindas..."
              rows="3"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            v-if="imagemWhatsappAtual && !frmCadastrarCampanha.excluirImagem"
          >
            <label>Imagem Whatsapp Atual:</label>

            <div class="row">
              <div class="col-md-4">
                <div class="card card-custom overlay">
                  <div class="card-body p-0">
                    <div class="overlay-wrapper">
                      <img :src="imagemWhatsappAtual" class="w-100 rounded" />
                    </div>
                    <div class="overlay-layer">
                      <button
                        type="button"
                        class="btn font-weight-bold btn-danger btn-shadow"
                        @click.prevent="
                          frmCadastrarCampanha.excluirImagem = true
                        "
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <label
              >Imagem Whatsapp (Formatos Aceitos: .jpg, .jpeg, .png):</label
            >
            <b-form-file
              name="imagemWhatsapp"
              @change="obterImagemWhatsapp"
              placeholder="Selecione ou arraste uma imagem aqui..."
              drop-placeholder="Solte a imagem aqui..."
              accept="image/jpeg, image/png, image/gif"
              browse-text="Procurar"
            ></b-form-file>
          </b-form-group>
        </template>
      </b-form>
    </b-modal>

    <b-modal
      id="mdlDetalheCampanha"
      ref="mdlDetalheCampanha"
      title="Detalhe Campanha"
      ok-title="Fechar"
      ok-only
      size="xl"
    >
      <p><b>Título:</b> {{ campanhaDetalhe.cam_titulo }}</p>
      <p><b>Texto SMS:</b> {{ campanhaDetalhe.cam_texto }}</p>
      <p><b>Texto Whatsapp:</b> {{ campanhaDetalhe.cam_texto_whatsapp }}</p>
      <p v-if="campanhaDetalhe.cam_anexo_whatsapp">
        <b>Imagem Whatsapp: </b>
        <a
          href="#"
          class="btn btn-link-primary"
          @click.prevent="
            visualizarImagemAnexo(campanhaDetalhe.cam_anexo_whatsapp)
          "
          >Clique aqui para visualizar</a
        >
      </p>

      <b-table
        hover
        small
        fixed
        show-empty
        :fields="cabecalhoDetalheCampanha"
        :items="campanhaDetalhe.ultimosEnvios"
      >
        <template #empty>
          <p class="text-center mb-0">Não existem envios.</p>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      id="mdlEnviarCampanha"
      ref="mdlEnviarCampanha"
      :title="`Enviar Campanha - Passo ${estagioEnvio}/3`"
      @hidden="limparFrmEnviarCampanha"
      @shown="inicializarMdlEnviarCampanha"
      @ok="enviarCampanha"
      size="xl"
      ignore-enforce-focus-selector=".swal2-container"
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="warning"
          @click="enviarMensagemTeste()"
          class="mr-auto"
        >
          Enviar teste
        </b-button>

        <b-button variant="danger" @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="secondary"
          @click="estagioEnvio--"
          v-if="estagioEnvio > 1"
        >
          Voltar
        </b-button>
        <b-button variant="success" @click="ok()">
          {{ textoBtnEnviarCampanha }}
        </b-button>
      </template>
      <b-form id="frmEnviarCampanha" ref="frmEnviarCampanha">
        <div v-show="estagioEnvio == 1" id="frmEnviarCampanhaEstagio1">
          <b-form-group>
            <label
              >Selecione o arquivo que contém os números para envio (Formatos
              Aceitos: .csv, .txt):</label
            >
            <b-form-file
              name="arquivoContatos"
              @change="obterArquivoContatos"
              placeholder="Selecione ou arraste um arquivo aqui..."
              drop-placeholder="Solte o arquivo aqui..."
              accept=".txt, .csv"
              browse-text="Procurar"
            ></b-form-file>
          </b-form-group>

          <b-form-group>
            <label>Descrição do envio:</label>
            <b-form-input
              name="descricao"
              v-model="frmEnviarCampanha.descricao"
              @keyup="descricaoGeradaAutomatica = false"
            ></b-form-input>
          </b-form-group>
        </div>

        <div v-show="estagioEnvio == 2" id="frmEnviarCampanhaEstagio2">
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Tipo de envio:</label>
                <b-form-radio-group
                  v-model="frmEnviarCampanha.tipoEnvio"
                  :options="tiposEnvio"
                  name="tipoEnvio"
                ></b-form-radio-group>
                <span
                  class="form-text text-muted"
                  v-if="conta.envia_whatsapp == 'S'"
                  >Quando selecionado "Whatsapp ou SMS", a prioridade de envio é
                  via Whatsapp e, em caso de falha, o envio é feito via
                  SMS.</span
                >
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label>Agendar envio:</label>
                <input
                  class="form-control"
                  type="datetime-local"
                  name="dataAgendamento"
                  v-model="frmEnviarCampanha.dataAgendamento"
                />
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <label>Informe a coluna que contém os telefones:</label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaTelefone"
                  :options="colunasArquivoContatos"
                  name="colunaTelefone"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group>
                <label>
                  Se necessário, informe a coluna que contém os nomes:
                </label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaNome"
                  :options="colunasArquivoContatos"
                  name="colunaNome"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <b-form-group>
                <label> Coluna da variável 1: </label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaVariavel1"
                  :options="colunasArquivoContatos"
                  name="colunaVariavel1"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label> Coluna da variável 2: </label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaVariavel2"
                  :options="colunasArquivoContatos"
                  name="colunaVariavel2"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label> Coluna da variável 3: </label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaVariavel3"
                  :options="colunasArquivoContatos"
                  name="colunaVariavel3"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-3">
              <b-form-group>
                <label> Coluna da variável 4: </label>
                <b-form-select
                  v-model="frmEnviarCampanha.colunaVariavel4"
                  :options="colunasArquivoContatos"
                  name="colunaVariavel4"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <b-form-group>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="ignorarCabecalho"
                  v-model="frmEnviarCampanha.ignorarCabecalho"
                  @change="lerArquivo"
                />
                <span></span>
                Ignorar primeira linha (cabeçalho)
              </label>
            </div>
          </b-form-group>

          <b-form-group>
            <label> Pré-visualização: </label>
            <b-table
              hover
              small
              fixed
              :fields="cabecalhoPreVisualizacao"
              :items="linhasTblPreVisualizacao"
            >
            </b-table>
          </b-form-group>
        </div>

        <div v-show="estagioEnvio == 3">
          <p>Confira abaixo as informações das mensagens que serão enviadas:</p>
          <div
            class="alert alert-custom alert-notice alert-light-primary fade show"
            role="alert"
          >
            <div class="alert-icon"><i class="flaticon2-avatar"></i></div>
            <div class="alert-text">
              {{ qtdTotalContatos }} contatos foram encontrados no arquivo!
            </div>
          </div>

          <div
            class="alert alert-custom alert-notice alert-light-danger fade show"
            role="alert"
          >
            <div class="alert-icon"><i class="flaticon2-warning"></i></div>
            <div class="alert-text" v-if="qtdContatosComErro > 0">
              {{ qtdContatosComErro }} mensagens possuem algum erro e não serão
              enviadas!
            </div>
            <div class="alert-text" v-else>
              Nenhum contato faltando dados ou com informações incorretas!
            </div>
          </div>

          <div
            class="alert alert-custom alert-notice alert-light-success fade show"
            role="alert"
          >
            <div class="alert-icon"><i class="flaticon2-paperplane"></i></div>
            <div class="alert-text">
              {{ qtdTotalContatos - qtdContatosComErro }} mensagens serão
              enviadas no total!
            </div>
          </div>

          <div v-if="enviarComInteligencia">
            <p>
              <span class="text-danger font-weight-bolder">ATENÇÃO:</span>
              Identificamos que o número vinculado a sua conta tem uma ativação
              recente, para previnir o bloqueio de seu número por envio de SPAM,
              recomendamos que você utilize nossa inteligência para enviar as
              mensagens de forma mais segura.
            </p>

            <b-form-group>
              <label>Selecione como deseja realizar o envio:</label>
              <b-form-radio-group
                v-model="frmEnviarCampanha.utilizarInteligencia"
                :options="opcoesUtilizarInteligencia"
                name="utilizarInteligencia"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import { detect as csvDetectDelimiter } from "csv-string";

export default {
  name: "envio",
  data() {
    return {
      frmCadastrarCampanha: {
        index: null,
        id: null,
        titulo: "",
        textoSMS: "",
        textoWhatsapp: "",
        imagemWhatsapp: null,
        excluirImagem: false
      },
      imagemWhatsappAtual: null,
      cabecalho: [
        {
          key: "cam_titulo",
          label: "Título",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "data_cadastro_formatada",
          label: "Dt. Cadastro",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "data_ultimo_envio_formatada",
          label: "Dt. Último Envio",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "actions",
          label: "Ações",
          sortable: false,
          class: "align-middle text-center"
        }
      ],
      linhas: [],
      paginaAtual: 1,
      registrosPorPagina: 20,
      totalRegistros: 0,
      quantidadeMensagens: 0,
      conta: {},
      contaEnviaWhatsapp: false,
      ativacaoNova: false,

      estagioEnvio: 1,
      frmEnviarCampanha: {
        campanha: {},
        arquivoContatos: null,
        descricao: "",
        tipoEnvio: "SMS",
        colunaTelefone: null,
        colunaNome: null,
        ignorarCabecalho: false,
        dataAgendamento: null,
        utilizarInteligencia: "N",
        delimitador: ",",
        colunaVariavel1: null,
        colunaVariavel2: null,
        colunaVariavel3: null,
        colunaVariavel4: null
      },
      textoBtnEnviarCampanha: "Avançar",
      descricaoGeradaAutomatica: true,
      tiposEnvio: [{ text: "SMS", value: "SMS" }],
      colunasArquivoContatos: [
        {
          key: null,
          text: ""
        }
      ],
      cabecalhoTblPreVisualizacao: [],
      linhasTblPreVisualizacao: [],
      opcoesUtilizarInteligencia: [
        {
          html: '<span class="text-warning font-weight-bold">Inteligência Wellon</span>',
          value: "S"
        },
        { text: "Envio imediato", value: "N" }
      ],

      qtdTotalContatos: 0,
      qtdContatosComErro: 0,

      campanhaDetalhe: {},
      cabecalhoDetalheCampanha: [
        {
          key: "cen_descricao",
          label: "Descrição",
          sortable: true,
          class: "align-middle"
        },
        {
          key: "data_cadastro_formatada",
          label: "Data de Cadastro",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "data_agendado_formatada",
          label: "Data da Execução",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "cen_tipo_envio",
          label: "Tipo Envio",
          sortable: true,
          class: "align-middle text-center"
        },
        {
          key: "cen_enviado",
          label: "Enviado?",
          sortable: true,
          class: "align-middle text-center"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Enviar Mensagem" }]);
    this.inicializar();
  },
  computed: {
    ...mapGetters(["validatorErrors"]),

    cabecalhoPreVisualizacao() {
      const self = this;

      return this.cabecalhoTblPreVisualizacao.map((obj) => {
        if (
          obj.coluna == self.frmEnviarCampanha.colunaTelefone ||
          obj.coluna == self.frmEnviarCampanha.colunaNome ||
          obj.coluna == self.frmEnviarCampanha.colunaVariavel1 ||
          obj.coluna == self.frmEnviarCampanha.colunaVariavel2 ||
          obj.coluna == self.frmEnviarCampanha.colunaVariavel3 ||
          obj.coluna == self.frmEnviarCampanha.colunaVariavel4
        ) {
          obj.variant = "info";
        } else {
          delete obj.variant;
        }

        let cabecalho = obj.key;
        if (obj.coluna == self.frmEnviarCampanha.colunaTelefone) {
          cabecalho += " TELEFONE";
        }

        if (obj.coluna == self.frmEnviarCampanha.colunaNome) {
          cabecalho += " NOME";
        }

        if (obj.coluna == self.frmEnviarCampanha.colunaVariavel1) {
          cabecalho += " VARIÁVEL 1";
        }

        if (obj.coluna == self.frmEnviarCampanha.colunaVariavel2) {
          cabecalho += " VARIÁVEL 2";
        }

        if (obj.coluna == self.frmEnviarCampanha.colunaVariavel3) {
          cabecalho += " VARIÁVEL 3";
        }

        if (obj.coluna == self.frmEnviarCampanha.colunaVariavel4) {
          cabecalho += " VARIÁVEL 4";
        }

        obj.label = cabecalho;

        return obj;
      });
    },

    enviarComInteligencia() {
      return (
        this.frmEnviarCampanha.tipoEnvio.includes("Whatsapp", "Ambos") &&
        this.ativacaoNova
      );
    }
  },

  watch: {
    estagioEnvio: function (estagio) {
      this.textoBtnEnviarCampanha = estagio == 3 ? "Gerar Envios" : "Avançar";
    }
  },

  methods: {
    inicializar() {
      ApiService.get("campanha").then((response) => {
        this.linhas = response.data.data.campanhas;
        this.conta = response.data.data.conta;

        if (this.conta.envia_whatsapp == "S") {
          this.frmEnviarCampanha.tipoEnvio = "Whatsapp";
          this.tiposEnvio = [
            { text: "Whatsapp", value: "Whatsapp" },
            { text: "SMS", value: "SMS" },
            { text: "Whatsapp ou SMS", value: "Ambos" }
          ];

          this.contaEnviaWhatsapp = true;

          if (this.conta.ativacaoNova == "S") {
            this.ativacaoNova = true;
          }
        }
      });
    },

    limparFrmCadastrarCampanha() {
      this.frmCadastrarCampanha = {
        index: null,
        id: null,
        titulo: "",
        textoSMS: "",
        textoWhatsapp: "",
        imagemWhatsapp: null,
        excluirImagem: false
      };

      this.imagemWhatsappAtual = null;
    },

    abrirMdlCadastrarCampanha(editar = false) {
      if (!editar) {
        this.limparFrmCadastrarCampanha();
      }

      this.$bvModal.show("mdlCadastrarCampanha");
    },

    validacaoMdlCadastrarCampanha() {
      const frmCadastrarCampanha = document.getElementById(
        "frmCadastrarCampanha"
      );
      const self = this;

      this.fv = formValidation(frmCadastrarCampanha, {
        fields: {
          titulo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          textoSMS: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  let valido = true;

                  if (self.contaEnviaWhatsapp) {
                    if (
                      !self.frmCadastrarCampanha.textoWhatsapp &&
                      !self.frmCadastrarCampanha.textoSMS
                    ) {
                      valido = false;
                    }
                  } else if (!self.frmCadastrarCampanha.textoSMS) {
                    valido = false;
                  }

                  return valido;
                }
              },
              blank: {}
            }
          },
          textoWhatsapp: {
            validators: {
              callback: {
                message: "Campo obrigatório.",
                callback: function () {
                  let valido = true;

                  if (self.contaEnviaWhatsapp) {
                    if (
                      !self.frmCadastrarCampanha.textoWhatsapp &&
                      !self.frmCadastrarCampanha.textoSMS
                    ) {
                      valido = false;
                    }
                  } else if (!self.frmCadastrarCampanha.textoWhatsapp) {
                    valido = false;
                  }

                  return valido;
                }
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvarCampanha(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          let formData = new FormData();

          for (var [chave, valor] of Object.entries(
            this.frmCadastrarCampanha
          )) {
            formData.append(chave, valor || "");
          }

          const config = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };

          ApiService.post("campanha/salvar", formData, config)
            .then((response) => {
              const campanha = response.data.data;
              this.$bvModal.hide("mdlCadastrarCampanha");

              if (this.frmCadastrarCampanha.index == null) {
                this.linhas.unshift(campanha);
              } else {
                this.linhas.splice(
                  this.frmCadastrarCampanha.index,
                  1,
                  campanha
                );
              }

              Swal.fire({
                title: "MENSAGEM",
                text: "Cadastrado com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    obterImagemWhatsapp(ev) {
      this.frmCadastrarCampanha.imagemWhatsapp = ev.target.files[0];
    },

    editarCampanha(campanha, index) {
      this.frmCadastrarCampanha = {
        index: index,
        id: campanha.campanha_id,
        titulo: campanha.cam_titulo,
        textoSMS: campanha.cam_texto,
        textoWhatsapp: campanha.cam_texto_whatsapp,
        imagemWhatsapp: null,
        excluirImagem: campanha.cam_anexo_whatsapp ? false : true
      };

      this.imagemWhatsappAtual = campanha.cam_anexo_whatsapp;

      this.abrirMdlCadastrarCampanha(true);
    },

    abrirMdlEnviarCampanha(campanha) {
      this.limparFrmEnviarCampanha();
      this.frmEnviarCampanha.campanha = campanha;
      this.$bvModal.show("mdlEnviarCampanha");
    },

    obterArquivoContatos(ev) {
      let arquivo = ev.target.files[0];

      if (!this.frmEnviarCampanha.descricao || this.descricaoGeradaAutomatica) {
        this.frmEnviarCampanha.descricao = arquivo.name
          .split(".")
          .slice(0, -1)
          .join(".");
      }

      const self = this;
      const reader = new FileReader();
      reader.onload = function () {
        self.frmEnviarCampanha.arquivoContatos = this.result;
        self.lerArquivo();
      };

      reader.readAsText(arquivo);
    },

    lerArquivo() {
      this.linhasTblPreVisualizacao = [];
      this.cabecalhoTblPreVisualizacao = [];
      this.colunasArquivoContatos = [
        {
          key: null,
          text: ""
        }
      ];

      let linhas = this.frmEnviarCampanha.arquivoContatos.split(/\r?\n/);
      linhas = linhas.filter((n) => {
        return n !== undefined && n !== null && n !== "";
      });

      let contador = 0;
      for (var linha = 0; linha < linhas.length; linha++) {
        if (contador < 2) {
          if (!this.frmEnviarCampanha.ignorarCabecalho || linha > 0) {
            const delimitador = csvDetectDelimiter(linhas[0]);
            this.frmEnviarCampanha.delimitador = delimitador;

            const colunas = linhas[linha].split(delimitador);
            let objLinhas = {};

            for (var coluna = 0; coluna < colunas.length; coluna++) {
              objLinhas[`#${coluna + 1}`] = colunas[coluna];

              if (contador == 0) {
                this.cabecalhoTblPreVisualizacao.push({
                  key: `#${coluna + 1}`,
                  label: `#${coluna + 1}`,
                  sortable: false,
                  coluna: coluna
                });

                this.colunasArquivoContatos.push({
                  value: coluna,
                  text: `#${coluna + 1}`
                });
              }
            }

            this.linhasTblPreVisualizacao.push(objLinhas);

            contador++;
          }
        }
      }
    },

    enviarCampanha(e) {
      e.preventDefault();

      if (this.estagioEnvio == 1) {
        this.fvEnviarCampanhaEstagio1.validate().then((status) => {
          if (status == "Valid") {
            this.estagioEnvio++;
          }
        });
      } else if (this.estagioEnvio == 2) {
        this.fvEnviarCampanhaEstagio2.validate().then((status) => {
          if (status == "Valid") {
            let linhas = this.frmEnviarCampanha.arquivoContatos.split(/\r?\n/);
            linhas = linhas.filter((n) => {
              return n !== undefined && n !== null && n !== "";
            });
            const delimitador = csvDetectDelimiter(linhas[0]);

            this.qtdTotalContatos = this.frmEnviarCampanha.ignorarCabecalho
              ? linhas.length - 1
              : linhas.length;

            let linhasComErro = 0;
            for (var linha = 0; linha < linhas.length; linha++) {
              let colunas = linhas[linha].split(delimitador);

              if (!this.frmEnviarCampanha.ignorarCabecalho || linha > 0) {
                const telefone = colunas[
                  this.frmEnviarCampanha.colunaTelefone
                ].replace(/\D/g, "");
                const telefoneValido = /^(?:55)?0?(\d{2})(\d{8,9})$/.test(
                  telefone
                );

                const validarTagNome = this.checarTag(
                  colunas[this.frmEnviarCampanha.colunaNome],
                  "{nome}"
                );

                const validarTagVariavel1 = this.checarTag(
                  colunas[this.frmEnviarCampanha.colunaVariavel1],
                  "{variavel1}"
                );

                const validarTagVariavel2 = this.checarTag(
                  colunas[this.frmEnviarCampanha.colunaVariavel2],
                  "{variavel2}"
                );

                const validarTagVariavel3 = this.checarTag(
                  colunas[this.frmEnviarCampanha.colunaVariavel3],
                  "{variavel3}"
                );

                const validarTagVariavel4 = this.checarTag(
                  colunas[this.frmEnviarCampanha.colunaVariavel4],
                  "{variavel4}"
                );

                if (
                  telefone.length < 10 ||
                  !telefoneValido ||
                  !validarTagNome ||
                  !validarTagVariavel1 ||
                  !validarTagVariavel2 ||
                  !validarTagVariavel3 ||
                  !validarTagVariavel4
                ) {
                  linhasComErro++;
                }
              }
            }

            this.qtdContatosComErro = linhasComErro;
            this.estagioEnvio++;
          }
        });
      } else if (this.estagioEnvio == 3) {
        ApiService.post("campanha/gerarEnvios", this.frmEnviarCampanha).then(
          () => {
            this.$bvModal.hide("mdlEnviarCampanha");
            Swal.fire({
              title: "MENSAGEM",
              text: "Envios gerados com sucesso!",
              icon: "success",
              heightAuto: false
            });
          }
        );
      }
    },

    limparFrmEnviarCampanha() {
      this.estagioEnvio = 1;
      this.frmEnviarCampanha = {
        campanha: {},
        arquivoContatos: null,
        descricao: "",
        tipoEnvio: this.contaEnviaWhatsapp ? "Whatsapp" : "SMS",
        colunaTelefone: null,
        colunaNome: null,
        ignorarCabecalho: false,
        dataAgendamento: null,
        utilizarInteligencia: this.ativacaoNova ? "S" : "N",
        delimitador: ",",
        colunaVariavel1: null,
        colunaVariavel2: null,
        colunaVariavel3: null,
        colunaVariavel4: null
      };

      this.descricaoGeradaAutomatica = true;
      this.colunasArquivoContatos = [
        {
          key: null,
          text: ""
        }
      ];
      this.cabecalhoTblPreVisualizacao = [];
      this.linhasTblPreVisualizacao = [];
    },

    inicializarMdlEnviarCampanha() {
      const self = this;

      this.frmEnviarCampanha.tipoEnvio =
        this.conta.envia_whatsapp == "S" ? "Whatsapp" : "SMS";
      this.frmEnviarCampanha.utilizarInteligencia = this.ativacaoNova
        ? "S"
        : "N";

      const frmEnviarCampanhaEstagio1 = document.getElementById(
        "frmEnviarCampanhaEstagio1"
      );

      this.fvEnviarCampanhaEstagio1 = formValidation(
        frmEnviarCampanhaEstagio1,
        {
          fields: {
            arquivoContatos: {
              validators: {
                notEmpty: {
                  message: "Selecione um arquivo para continuar."
                },
                file: {
                  extension: "txt,csv",
                  maxSize: 5242880,
                  message:
                    "Selecione um arquivo nos formatos .TXT ou .CSV e tamanho máximo de 5mb."
                },
                blank: {}
              }
            },
            descricao: {
              validators: {
                notEmpty: {
                  message: "Campo obrigatório."
                },
                blank: {}
              }
            }
          },
          plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap()
          }
        }
      );

      const frmEnviarCampanhaEstagio2 = document.getElementById(
        "frmEnviarCampanhaEstagio2"
      );

      this.fvEnviarCampanhaEstagio2 = formValidation(
        frmEnviarCampanhaEstagio2,
        {
          fields: {
            tipoEnvio: {
              validators: {
                notEmpty: {
                  message: "Campo obrigatório."
                },
                blank: {}
              }
            },
            colunaTelefone: {
              validators: {
                notEmpty: {
                  message: "Campo obrigatório."
                },
                blank: {}
              }
            },
            colunaNome: {
              validators: {
                callback: {
                  message:
                    "Você precisa informar esta coluna para usar a tag {nome} na mensagem.",
                  callback: function (input) {
                    const validarTagNome = self.checarTag(
                      input.value,
                      "{nome}"
                    );
                    return validarTagNome;
                  },
                  blank: {}
                }
              }
            },
            ignorarCabecalho: {
              validators: {
                blank: {}
              }
            }
          },
          plugins: {
            trigger: new Trigger(),
            bootstrap: new Bootstrap()
          }
        }
      );
    },

    checarTag(valor, tag) {
      let possuiTagNome = -1;
      if (this.frmEnviarCampanha.campanha.cam_texto) {
        possuiTagNome = this.frmEnviarCampanha.campanha.cam_texto.indexOf(tag);
      }

      let possuiTagNomeWhatsapp = -1;
      if (this.frmEnviarCampanha.campanha.cam_texto_whatsapp) {
        possuiTagNomeWhatsapp =
          this.frmEnviarCampanha.campanha.cam_texto_whatsapp.indexOf(tag);
      }

      if (
        (possuiTagNome !== -1 ||
          (this.contaEnviaWhatsapp && possuiTagNomeWhatsapp !== -1)) &&
        !valor
      ) {
        return false;
      }

      return true;
    },

    enviarMensagemTeste() {
      (async () => {
        const { value: telefone } = await Swal.fire({
          title: "Informe o telefone para envio:",
          input: "text",
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          reverseButtons: true,
          inputValidator: (value) => {
            if (!value) {
              return "Informe um telefone!";
            }
          }
        });

        if (telefone) {
          let parametrosEnvio = {
            idCampanha: this.frmEnviarCampanha.campanha.campanha_id,
            tipoEnvio: this.frmEnviarCampanha.tipoEnvio,
            telefone: telefone
          };

          ApiService.post("campanha/enviarTeste", parametrosEnvio).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Teste enviado com sucesso!",
              icon: "success",
              heightAuto: false
            });
          });
        }
      })();
    },

    abrirMdlDetalheCampanha(campanha) {
      this.campanhaDetalhe = campanha;
      this.$bvModal.show("mdlDetalheCampanha");
    },

    excluirCampanha(campanha, index) {
      const self = this;

      Swal.fire({
        title: "Deseja realmente excluir esta campanha?",
        showCancelButton: true,
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("campanha/excluirCampanha", campanha).then(() => {
            Swal.fire({
              title: "MENSAGEM",
              text: "Campanha excluída com sucesso!",
              icon: "success",
              heightAuto: false
            });

            self.linhas.splice(index, 1);
          });
        }
      });
    },

    visualizarImagemAnexo(url) {
      Swal.fire({
        imageUrl: url,
        confirmButtonText: "Fechar"
      });
    }
  }
};
</script>
